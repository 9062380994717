<template>
  <div
    class="chat-box"
    id="chatView"
  >
    <keep-alive include="nowchat">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "chat",
};
</script>

<style lang="scss" scope>
.chat-box {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/home_image_background2.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: 100% 100%;
  padding-top: 1.17rem;
  overflow-y: scroll;
  position: absolute;
  .header-nav {
    background-color: transparent;
    &::after {
      border: none;
    }
    .van-icon {
      color: #fff;
    }
    .van-nav-bar__right {
      font-size: 0.32rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.48rem;
      img {
        width: 0.69rem;
        height: 0.69rem;
        margin-right: 0.16rem;
      }
    }
  }
  .chat-content {
    padding: 0 0.4rem;
    padding-bottom: 2rem;
    dl.rebot-style {
      dd {
        max-width: 6.64rem;
        .headerContent {
          min-height: 2rem;
        }
        .content,
        .headerContent {
          background: rgba(122, 153, 250, 0.6);
          border-radius: 0.05rem 0.53rem 0.53rem 0.53rem;
          border: 0.03rem solid rgba(255, 255, 255, 0.25);
          color: #ffffff;
          margin-left: 0.13rem;
          position: relative;
        }
      }
    }
    dl.custom-style {
      flex-direction: row-reverse;
      dd {
        max-width: 7rem;
        .content {
          background: #ffffff;
          border-radius: 0.53rem 0.05rem 0.53rem 0.53rem;
          opacity: 0.9;
          color: #2150c8;
          padding: 0.32rem 0.34rem;
          margin-right: 0.13rem;
        }
      }
    }
    dl {
      display: flex;
      margin-bottom: 0.5rem;
      dt > img {
        width: 1.12rem;
        height: 1.12rem;
        border-radius: 50%;
      }
      .add-chat {
        color: #ffffff;
        width: 3.36rem;
        height: 0.44rem;
        background: rgba($color: #fff, $alpha: 0.3);
        border-radius: 0rem 0.22rem 0.22rem 0rem;
        text-indent: 1.5em;
        line-height: 0.44rem;
        float: right;
        position: relative;
        margin: -0.2rem -7.75rem 0 0;
        opacity: 0.9;
        img {
          position: absolute;
          left: -0.4rem;
          top: -0.12rem;
          height: 0.75rem;
          width: 0.75rem;
        }
      }
      .go-look {
        width: 2.07rem;
        height: 0.44rem;
        background: rgba($color: #fff, $alpha: 0.3);
        border-radius: 0.22rem;
        font-size: 0.32rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.44rem;
        text-align: center;
        opacity: 0.9;
        margin: -0.373333rem -7.75rem 0 0;
        float: right;
      }
      dd {
        .content {
          // max-width: 6.13rem;
          height: auto;
          font-size: 0.4rem;
          font-weight: 500;
          line-height: 0.56rem;
          padding: 0.32rem 0.24rem;
          margin-top: 0.27rem;
        }
      }
    }
  }
}
</style>
